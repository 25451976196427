<template>
  <div id="WelfareIndex">
      <!-- <div class="cart_icon">
          <i class="el-icon-shopping-cart-full"></i>
      </div> -->
      <!-- <Header/> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <!-- <Footer/> -->
  </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector';
// import Header from '../../components/header.vue';
// import Footer from '../../components/footer.vue';
export default {
  // components:{
  //   Header,
  //   Footer,
  // },
  name: 'WelfareIndex',
  data() {
    return {
      clientHeight: 0,    // 声明data
    };
  },
  computed:{
  },
  watch:{
    
  },
  created() {
  },
  mounted() {
    var _this = this;
    const erd = elementResizeDetectorMaker();
    // 监听WelfareIndex 节点的高度变化
    erd.listenTo(document.getElementById("WelfareIndex"), element =>{
      _this.$nextTick(()=>{
        _this.setClientHight();
      })
    })
    // 挂载读取页面高度
    // 窗口页面高度变更
    window.onresize = function(){
      _this.setClientHight();
    } 
  },
  methods: {
    // 设置页面高度
    setClientHight() {
        var height = document.getElementById('WelfareIndex').offsetHeight
        // 设置iframe一个最小高度
        if(height < 920){
          height = 920
        }
        window.parent.postMessage({
          height
        }, '*')
    },
  },
  beforeDestroy() {
    
  },
  destroyed() {
    
    
  },
};
</script>

<style scoped lang="less">
.WelfareIndex{
    // position: relative;
    scroll
    .cart_icon{
        position: fixed;
        top: 150px;
        right: 16px;
        width: 80px;
        height: 80px;
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(245, 34, 45, 0.08);
        opacity: 1;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        color: #F5222D;
        cursor: pointer;

    }
}
</style>
